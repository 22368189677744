import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"

import {
    talkTypesContainer,
    talkTypeContainer,
    talkTypeInfo,
    talkType,
} from "./talk-type-list.module.scss"

import { GatsbyImage } from "gatsby-plugin-image"


const TalkTypeItem = ({ node }) => {
    return (
        <Link key={node.id} to={node.fields.path} className={talkTypeContainer}>
            <div className={talkType}>
                <GatsbyImage
                    image={node.cover.childImageSharp.gatsbyImageData}
                    placeholder="blurred"
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt=""
                    key={node.id} />

                <div className={talkTypeInfo}>
                    <h2>
                        {node.topic}
                    </h2>
                    <p>
                        {node.title}
                    </p>

                </div>
            </div>
        </Link>
    )
}


const TalkTypeList = ({ talkTypes }) => {
    talkTypes.sort((a, b) => {
        return new Date(b.node.priority) - new Date(a.node.priority)
    })

    return (
        <section className={talkTypesContainer}>
            {talkTypes.map(({ node }) => TalkTypeItem({ node }))}
        </section>
    )
}
TalkTypeList.propTypes = {
    talkTypes: PropTypes.array.isRequired
}
export default TalkTypeList

export const query = graphql`
    fragment TalkTypeFragment on TalkTypesJson {
        type
        audience
        topic
        main
        priority
        bio
        duration
        id
        questions
        languages {
            language
            title
            description
        }
        title
        fields {
            path
            name
        }
        relatedTalks {
            ...TalkFragment
        }
    }
`
